import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule, } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { HeaderComponent } from "./header/header.component";
import { CoreModule } from "./core/core.module";
import { AlertComponent } from "./alert/alert.component";
import { RouterModule } from "@angular/router";
import { RoundProgressModule } from "angular-svg-round-progressbar";
import { PipesModule } from "./core/pipes/pipes.module";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { MaterialModule } from "./material/material.module";
import { DynamicDialogModule } from "primeng-lts/dynamicdialog";
import { NgxMaskModule } from "ngx-mask";
import { DialogModule } from "primeng/dialog";
import { MobileLandingPageComponent } from "./mobile-landing-page/mobile-landing-page.component";
import { ModalDialogModule } from "ngx-modal-dialog";
import { DropdownModule } from 'primeng/dropdown';
import { ModalModule } from "ngx-modal-bootstrap";
import { SuccessSubscriptionPopupComponent } from "./successSubscriptionPopup/successSubscriptionPopup.component";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { UpgradeLogoutComponent } from './upgrade-logout/upgrade-logout.component';
import { UpdgradeModelComponent } from './updgrade-model/updgrade-model.component';
import { TooltipModule } from 'primeng/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { CalendarModule } from 'primeng/calendar';
import { ToasterService } from 'src/app/custom-toaster/toaster.service';
import { NewDashboardComponent } from './new-dashboard/new-dashboard.component'
import { DatePipe } from "@angular/common";
import { ProgressBarModule } from 'primeng/progressbar';
import { GettingStartedGuideModalComponent } from './getting-started-guide-modal/getting-started-guide-modal.component';
import { GettingStartedGuidePopupComponent } from './getting-started-guide-modal/getting-started-guide-popup/getting-started-guide-popup/getting-started-guide-popup.component';
import { ToasterModule } from "./custom-toaster/toaster.module";
import { InventoryService } from "./inventory/inventory.service";
import { InputNumberModule } from 'primeng/inputnumber';
import { SettingsService } from "./settings/settings.service";
import { WixLoginComponent } from "./wix-login/wix-login.component";
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    AlertComponent,
    ConfirmDialogComponent,
    MobileLandingPageComponent,
    SuccessSubscriptionPopupComponent,
    UpgradeLogoutComponent,
    UpdgradeModelComponent,
    NewDashboardComponent,
    GettingStartedGuideModalComponent,
    GettingStartedGuidePopupComponent,
    WixLoginComponent
  ],
  bootstrap: [AppComponent],
  imports: [TooltipModule,
    MatDialogModule,
    MatTooltipModule,
    CalendarModule,
    ModalModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    CoreModule,
    RoundProgressModule,
    ToasterModule,
    PipesModule,
    TooltipModule,
    ProgressBarModule,
    ModalDialogModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-top-center",
      preventDuplicates: true,
      closeButton: true,
    }),
    NgxSpinnerModule,
    MaterialModule,
    DynamicDialogModule,
    NgxMaskModule.forRoot(),
    DialogModule,
    DropdownModule,
    InputNumberModule,
    

  ],
  providers: [ToasterService, DatePipe, InventoryService, SettingsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService, DataService } from "../core";
import { DashboardService } from "../core/services/dashboard.service";
import { MatDialog } from "@angular/material/dialog";
import { AppComponent } from "../app.component";
import { UpgradeLogoutComponent } from "../upgrade-logout/upgrade-logout.component";
import { resourceType } from "../inventory/purchase-order-interface";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { InventoryService } from "../inventory/inventory.service";
import { DatePipe } from "@angular/common";
import { GettingStartedGuideModalComponent } from "../getting-started-guide-modal/getting-started-guide-modal.component";
import { SetUpProfileModalComponent, firstTimeSetup } from "../account/set-up-profile-modal/set-up-profile-modal.component";
import { ToasterService } from "../custom-toaster/toaster.service";
import { FulfillmentService } from "../fulfillment/fulfillment.service";
import { Subscription } from "rxjs";
import { SubscriptionDetail } from "../core/models/SubscriptionDetail";
import { SettingsService } from "../settings/settings.service";
import { NewOrderBlockModalComponent } from "../customcommon/new-order-block-modal/new-order-block-modal.component";
import { environment } from "src/environments/environment";
import { ModalService } from "../core/services/modal.service";

const ERROR_MESSAGE: string = "Some error occured. Please try again later";
const PO_ROUTE: string = "/inventory/purchaseorderslisting/purchaseorders";
const YESTERDAY: string = "Yesterday";
const TODAY: string = "Today";
const TOMORROW: string = "Tomorrow";
const DATE_FORMAT: string = "MMM dd, yyyy";
const COMMITTED: string = "Committed";
const IN_STOCK: string = "In stock";
const NEED_STOCK: string = "Need stock";
const SUCCESS: string = "success";
const ERROR: string = "error";
const RESOURCE_TYPE: string = "resource_type";
const COMPANY_NAME: string = "company_name";
const IN_PROGRESS_HEADING: string = "Are you sure you want to fulfill this order and update inventory?";
const IN_PROGRESS_MSG:string = "Changing the production status will commit the required supplies to fulfill this order. The inventory for <b><total_resources> resources</b> will update accordingly. This action can not be undone. Are you sure you want to proceed?"
const READY_TO_SHIP_HEADING: string = "Are you sure this order is ready to ship?";
const READY_TO_SHIP_MSG: string = "Changing the status will mean this order has been manufactured and is ready to ship. This action can not be undone. Are you sure you want to proceed?";
const STATUS_BLOCK_HEADING:string = "You don’t have enough resources to fulfill this order";
const STATUS_BLOCK_MSG:string = "There’s not enough inventory on to hand to fulfill this order. Create purchase orders to replenish any stock that is missing."
const IN_PROGRESS_SUCCESS_MSG:string="Order <order_number> is now in progress and inventory has been updated.";
const READY_TO_SHIP_SUCCESS_MSG:string = "Order <order_number> is now ready to ship.";
const SHIPPED_SUCCESS_MSG:string="Order <order_number> has been shipped and removed from the manufacturing queue.";
const INGREDIENT:string = "Ingredient";
const RETURN_URL = `${environment.baseUrl}/dashboard`;
@Component({
  selector: "app-new-dashboard",
  templateUrl: "./new-dashboard.component.html",
  styleUrls: ["./new-dashboard.component.css"],
})
export class NewDashboardComponent implements OnInit, OnDestroy {
  user: any;
  userId: number;
  subscription: Subscription;
  dashboardPurchaseOrderList = [];
  lowStockList = [];
  dashboardManufacturingOrderList = [];
  startGuideStepList: [] = [];
  completedSteps: number = 0;
  totalSteps: number = 0;
  progressPercentage: number;
  isStepsCompleted: boolean = false;
  isPODataAvailable: boolean = false;
  isMODataAvailable: boolean = false;
  isStockDataAvailable: boolean = false;
  supplierIdForPO: number;
  typeOfresource: any;
  resourceForPo: any;
  poNumberByStock: any;
  isExpectedDate: boolean = false;
  isStatusDropDownVisible: boolean[] = [];
  currentOpenIndex: number = -1;
  modalHeading: string;
  modalMessage: string;
  orderId: any;
  orderStatus: any;
  orderNumber: any;
  new_po_id: number = 1;
  isMOStatusModalOpen: boolean = false;
  totalResourcesCount: number;
  showToaster: boolean = false;
  nextMONumber: string;
  nextMOId:number = 1;
  fulfillmentRequirementIngredientDetailsList = []
  fulfillmentRequirementMaterialDetailsList = []
  requirementAddOnIngedientsDetailsList = []
  resourcesIds = []
  requirementAddOnIngedients:any
  requirementMaterialsList:any
  InventoryUnitsValue:any;
  countCurrResourceItem:number;
  totalCurrResourceItems:number;
  isBlockStatus:boolean = false;
  productionStatus:number;
  dataInStock: number;
  isGuideCompleted:boolean = false;
  userName: string;
  orderLimitPerMonth: number = 0;
  orderLimitUsed: number = 0;
  subscriptionInfo: SubscriptionDetail;
  isFirstTimeSetup: boolean = false;
  renewalDate:any;
  formattedRenewalDate:any;
  purchaseQuantityForPo:number=0;
  constructor(
    private authService: AuthService,
    private dialogRef: MatDialog,
    private dashboardServ: DashboardService,
    private spinner: NgxSpinnerService,
    private appComponent: AppComponent,
    private router: Router,
    private inventoryService: InventoryService,
    private datePipe: DatePipe,
    private renderer: Renderer2,
    private toasterService: ToasterService,
    private fulfillment: FulfillmentService,
    private settingsService: SettingsService,
    private dataService: DataService,
    private modalService:ModalService
  ) {
    if (this.authService.currentUser) {
      this.user = this.authService.currentUser[0];
      this.userId = this.authService.currentUser[0].id;
    }
    this.authService.user.subscribe((x) => {
      this.user = x[0];
    });
  }

  ngOnInit(): void {
    debugger;
    this.requestDataFromMultipleSources();
    firstTimeSetup.subscribe((res)=>{
      this.isFirstTimeSetup = res.message;
      setTimeout(()=>{
        window.scrollTo(0,0);
      },50)
    })
    this.appComponent.ngOnInit();
    let user = JSON.parse(localStorage.getItem("user"));
    if (
      !user[0].user_first_name &&
      !user[0].user_last_name &&
      !user[0].user_title &&
      !user[0].business_name
    ) {
      this.openProfileModal();
    }
    this.toasterSubscription();
    let freeTrialFromlocalstorageData = user[0].is_free_trial;
    this.isGuideCompleted = user[0].is_guide_completed;
    if(this.user.role == 'user'){
      this.getSubscriptionDetails()
    }

    // if (user[0].DaysLeft <= 0 && user[0].DaysLeft != null) {
    //   this.dialogRef.open(UpgradeLogoutComponent);
    // }
    // if (
    //   this.appComponent.freeTrialdaysLeft <= 0 &&
    //   freeTrialFromlocalstorageData
    // ) {
    //   this.dialogRef.open(UpgradeLogoutComponent);
    // }
    // if (user[0].DaysLeft <= 0 && user[0].DaysLeft != null) {
    //   this.dialogRef.open(UpgradeLogoutComponent);
    // }
    setTimeout(()=>{
      window.scrollTo(0,0);
    },50)
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.toasterService.setData(null);
    }
  }
  toasterSubscription() {
    this.subscription = this.toasterService.data$.subscribe((data) => {
      if (data) {
        this.showToasterOnClick();
      }
    });
  }
  openProfileModal() {
    this.renderer.addClass(document.body, "no-scroll");
    this.dialogRef.open(SetUpProfileModalComponent, {
      disableClose: true,
      data: {
        userId: this.userId,
      },
    });
  }
  getSubscriptionDetails() {
    this.settingsService.getSubscriptionDetailsByUser(this.userId).subscribe(
      (item) => {
        if (item != null) {
          this.subscriptionInfo = item[0];
          this.renewalDate=this.subscriptionInfo.renewal_date;
          this.formattedRenewalDate=this.datePipe.transform(this.renewalDate,'yyyy-MM-dd')
      
            //  var localStorageRenewlDate=this.user.renewal_date;
            //  console.log("localStorageRenewalDate",localStorageRenewlDate);
            //  console.log("formattedDate",this.formattedRenewalDate);
            //  if(this.formattedRenewalDate!=localStorageRenewlDate)
            //   {
            //     debugger;
            //      localStorage.removeItem(this.user.renewal_date);
            //       localStorage.setItem("user.renewal_date",this.formattedRenewalDate);
            //       console.log("newSetLocalStorage",this.user.renewal_date);
            //   }
          if(this.subscriptionInfo.stripe_product_id == 1){
            this.orderLimitPerMonth = 30;
          }
          
          else if(this.subscriptionInfo.stripe_product_id == 2){
            this.orderLimitPerMonth = 100;
          }
          if (this.formattedRenewalDate<=this.getCurrentDate())
            {
              this.dialogRef.open(UpgradeLogoutComponent, {
                disableClose: true
              });
            }
        else{
             this.modalService.setModalOpen(false);
            }

        }
      },
      (err) => {
        this.subscriptionInfo = null;
        console.log(err)
      }
    );
  }
  getCurrentDate()
  {
  const formattedCurrentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
   return formattedCurrentDate;
  }
  async requestDataFromMultipleSources() {
    this.dashboardPurchaseOrderList = [];
    this.lowStockList = [];
    this.spinner.show();
    this.dashboardServ.requestDataForDashboard(this.userId).subscribe(
      async (responseList) => {
        this.spinner.hide();
        if (
          responseList[0] != null &&
          responseList[0].ResponseData.length > 0
        ) {
          this.startGuideStepList = responseList[0].ResponseData;
          this.calculateCompletedAndTotalStartGuideSteps();
          this.progressPercentage =
            (100 / this.totalSteps) * this.completedSteps;
          this.isStepsCompleted = this.completedSteps == 15 ? true : false;
          if(this.isStepsCompleted && !this.isGuideCompleted){
            let model = {user_id: this.userId}
            this.authService.UpdateGuideCompleted(model).subscribe((resp)=>{
              if(resp[0].ins_upd_flag === 'Y'){
                let user = JSON.parse(localStorage.getItem("user"));
                user[0].is_guide_completed = true;
                localStorage.setItem("user", JSON.stringify(user)); 
              }
              else{
                console.log('Error While guide Completing')
              }
            })
          }
        }

        if ( responseList[1] != null && responseList[1].ResponseData.length > 0
        ) {
          this.dashboardPurchaseOrderList = responseList[1].ResponseData;
          this.new_po_id = this.dashboardPurchaseOrderList[0].new_po_id;
          this.isPODataAvailable = true;
        }
        else{
          this.isPODataAvailable = false;
        }

        if (
          responseList[2] != null &&
          responseList[2].ResponseData.length > 0
        ) {
          this.lowStockList = responseList[2].ResponseData;
          this.isStockDataAvailable = true;
        }
        this.orderLimitUsed = responseList[3].Item2[0];
        if (responseList[3].Item1 != null && responseList[3].Item1.length > 0) {
          this.dashboardManufacturingOrderList = responseList[3].Item1;
          // this.orderLimitUsed = this.dashboardManufacturingOrderList[0].order_used_per_month
          this.nextMOId = this.dashboardManufacturingOrderList[0].new_mo_id;
          this.isMODataAvailable = true;
        }
        else{
          this.isMODataAvailable = false;
        }
        this.generatePO();
        this.generateMO();
      },
      (err) => {
        this.spinner.hide();
        this.generatePO();
        this.errorMessage(err);
      }
    );
  }
  calculateCompletedAndTotalStartGuideSteps() {
    this.totalSteps = 0;
    this.completedSteps = 0;
    this.startGuideStepList.forEach((data: any) => {
      this.totalSteps += data.TotalSteps;
      this.completedSteps += data.CompletedSteps;
    });
  }
  generatePO() {
    const timeNow = new Date();
    const currYear = timeNow.getFullYear();
    const currMonth = (timeNow.getMonth() + 1).toString().padStart(2, "0");
    const orderCount = this.new_po_id.toString().padStart(4, "0");
    const formattedDate = `${currMonth}${currYear}-${orderCount}`;
    this.poNumberByStock = formattedDate;
  }
  generateMO() {
    const timeNow = new Date();
    const year = timeNow.getFullYear().toString().slice(-2);
    const month = (timeNow.getMonth() + 1).toString().padStart(2, '0');
    const date = timeNow.getDate().toString().padStart(2, '0');
    const formattedDate = `${month}${date}${year}-${this.nextMOId}`;
    this.nextMONumber = formattedDate;
  }

  checkDate(date: string): string {
    const arrivalDate = new Date(date);
    arrivalDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const diffInTime = arrivalDate.getTime() - today.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    if (diffInDays === -1) {
      return YESTERDAY;
    } else if (diffInDays === 0) {
      return TODAY;
    } else if (diffInDays === 1) {
      return TOMORROW;
    } else {
      return this.datePipe.transform(date, DATE_FORMAT);
    }
  }
  createPoOrder(id: any) {
    this.generatePO();
    let resource = this.lowStockList.find((x) => x.id == id);
    this.supplierIdForPO = this.lowStockList.find(
      (x) => x.id == id
    ).supplier_id;
    if (resource.ItemType == INGREDIENT) {
      this.inventoryService.getIngredientById(resource.id).subscribe(
        (res) => {
          if (res.length > 0) {
            this.resourceForPo = res[0];
            this.resourceForPo[RESOURCE_TYPE] = resourceType.ingredient;
            this.resourceForPo[COMPANY_NAME] = resource.company_name;
            this.supplierIdForPO = this.resourceForPo.supplier_id;
            this.typeOfresource = 1;
            this.createRoutingLink(id);
          } else {
            this.errorMessage(ERROR_MESSAGE);
          }
        },
        (err) => {
          this.errorMessage(err);
        }
      );
    } else {
      this.inventoryService.getMaterialById(resource.id).subscribe(
        (res) => {
          if (res.length > 0) {
            this.resourceForPo = res[0];
            this.resourceForPo[RESOURCE_TYPE] = resourceType.material;
            this.resourceForPo[COMPANY_NAME] = resource.company_name;
            this.supplierIdForPO = this.resourceForPo.supplier_id;
            this.typeOfresource = 2;
            this.createRoutingLink(id);
          } else {
            this.errorMessage(ERROR_MESSAGE);
          }
        },
        (err) => {
          this.errorMessage(err);
        }
      );
    }
  }
  createRoutingLink(id: any) {
    this.router.navigate(
      [
        PO_ROUTE,
        this.supplierIdForPO,
        id,
        JSON.stringify(this.resourceForPo),
        this.typeOfresource,
        this.poNumberByStock,
        this.purchaseQuantityForPo
      ],
      { queryParams: { dashboard: true } }
    );
  }


  onPOStatusChange(item: any, status: number) {
    if(status==2)
    {
      this.spinner.show();
      var model = {
        user_id: this.userId,
        id:item.id,
        status: status,
      };
      this.spinner.show();
      this.inventoryService
        .updatePurchaseOrderStatus(model)
        .subscribe((response: any) => {
          this.spinner.show();
         
        });
      
    }
    this.requestDataFromMultipleSources();
  }

  openStartGuideDialog() {
    this.spinner.show();
    this.dialogRef.open(GettingStartedGuideModalComponent);
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent): void {
    if (this.currentOpenIndex !== -1) {
      this.isStatusDropDownVisible[this.currentOpenIndex] = false;
      this.currentOpenIndex = -1;
    }
  }
  toggleStatusDropDown(event: MouseEvent, index: number) {
    event.stopPropagation();
    if (this.currentOpenIndex !== -1 && this.currentOpenIndex !== index) {
      this.isStatusDropDownVisible[this.currentOpenIndex] = false;
    }
    this.isStatusDropDownVisible[index] = !this.isStatusDropDownVisible[index];
    this.currentOpenIndex = this.isStatusDropDownVisible[index] ? index : -1;
  }

  onMOStatusChange(id: number, status: number) {
    if(!(this.dataInStock == 1) && this.productionStatus == 0){
      this.isBlockStatus = true;
      this.modalHeading =
        STATUS_BLOCK_HEADING;
      this.modalMessage = STATUS_BLOCK_MSG;
      this.isMOStatusModalOpen = true;
      return;
    }
    this.spinner.show();
    var model = {
      user_id: this.userId,
      id: id,
      status: status,
      updateInventoryData: this.resourcesIds
    }
    this.fulfillment.updateManufacturingOrderStatus(model).subscribe((response: any) => {
      this.spinner.hide();
      this.isMOStatusModalOpen = false;
      if(status == 1){
        this.successMessage(IN_PROGRESS_SUCCESS_MSG.replace('<order_number>',`${this.orderNumber}`))
      }
      else if(status == 2){
        this.successMessage(READY_TO_SHIP_SUCCESS_MSG.replace('<order_number>',`${this.orderNumber}`));
      }
      else{
        this.successMessage(SHIPPED_SUCCESS_MSG.replace('<order_number>',`${this.orderNumber}`));
      }
      this.requestDataFromMultipleSources();
    })
  }
  getResourceStatus(data: any): string {
    if (data.production_status == 0) {
      var expectedDate;
      data.productList.forEach((item) => {
        if (item.resource_expected_date != null) {
          expectedDate = item.resource_expected_date;
          this.isExpectedDate = true;
          return;
        }
      });
      if ((expectedDate != null || undefined) && (data.in_stock == 3)) {
        return this.checkDate(expectedDate);
      } else {
        if (data.in_stock == 1) {
          return IN_STOCK;
        } else {
          return NEED_STOCK;
        }
      }
    } else {
      return COMMITTED;
    }
  }
  getFulfillmentRequirementDetails(pId: any, packId: any, units: any){
    this.fulfillment.fulfillmentRequirementDetails(pId, packId)
    .subscribe(response => {
      //console.log(response);
      
      response.Item1.forEach(data => {
        this.fulfillmentRequirementIngredientDetailsList.push(data);
      })
      response.Item2.forEach(data => {
        data["sub_units"] = data.inventory_units * units;
        data["quantity"] = data.quantity * units;
        this.fulfillmentRequirementMaterialDetailsList.push(data);
      })
      response.Item3.forEach(data => {
        // data["ingredient_weight"] = (data.ingredient_weight / (data.total_items_recipe_makes/data.product_item_quantity)) * units;
        data["ingredient_weight"] = (data.ingredient_weight * data.product_item_quantity * units);
        this.requirementAddOnIngedientsDetailsList.push(data);
      })
      response.Item4.forEach(data => {
        data["ingredient_weight"] = (data.ingredient_weight / (data.total_items_recipe_makes/data.product_item_quantity)) * units;
        this.requirementAddOnIngedientsDetailsList.push(data);
      })
          this.calculateGramsForRequirement(units);
          this.calculateSubUnitsForRequirement(units);
          this.countCurrResourceItem+=1;
          if(this.countCurrResourceItem === this.totalCurrResourceItems){
            this.updateIngredientInventory();
            this.updateMaterialInventory();
          }
    });
  }
  getResourceCount(data: any){
    this.totalCurrResourceItems = 0;
    this.countCurrResourceItem=0;
    this.fulfillmentRequirementIngredientDetailsList = [];
    this.fulfillmentRequirementMaterialDetailsList = [];
    this.requirementAddOnIngedientsDetailsList = [];
    this.resourcesIds = [];
    const ingredientlIds = new Set<string>();
    const materialIds = new Set<string>();
    this.totalCurrResourceItems = data.productList.length;
    data.productList.forEach(item => {
      if(item.ingredient_ids)
      {
        item.ingredient_ids.forEach(x => {
          ingredientlIds.add(x);
        })
      }
      if(item.material_ids)
      {
        item.material_ids.forEach(x => {
          materialIds.add(x);
        })
      }
      
      this.getFulfillmentRequirementDetails(item.product_id, item.packaging_id, item.units);
    })
    this.totalResourcesCount = ingredientlIds.size + materialIds.size;
    this.orderId = data.id;
    this.orderNumber = data.order_number;
    this.productionStatus = data.production_status;
    this.dataInStock  = data.in_stock
  }
calculateGramsForRequirement(units: any){
  this.requirementAddOnIngedients = [];
  var newArray = [];
  var combinedData  = []
  this.requirementAddOnIngedientsDetailsList.forEach(function(item) {
    var index = newArray.findIndex(function(element) {
        return element.ingredient_id === item.ingredient_id;
    });

    if (index !== -1) {
      // newArray[index].ingredient_weight += item.ingredient_weight * units;
      newArray[index].ingredient_weight += item.ingredient_weight;
    } else {
      newArray.push({
            ingredient_id: item.ingredient_id,
            ingredient_weight: item.ingredient_weight
            // ingredient_weight: item.ingredient_weight * units
        });
    }
    
  });
  //console.log(newArray);
  const array2Map = this.fulfillmentRequirementIngredientDetailsList.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
}, {});
newArray.forEach(item => {
  const id = item.ingredient_id;
  if (array2Map[id]) {
      combinedData.push({
          ...item,
          ...array2Map[id]
      });
  }
});
this.requirementAddOnIngedients = combinedData
//console.log(this.requirementAddOnIngedients);
}

calculateSubUnitsForRequirement(units: any){
  const uniqueData = [];

  this.fulfillmentRequirementMaterialDetailsList.forEach(item => {
  const existingItem = uniqueData.find(uItem => uItem.id === item.id);
  if (existingItem) {
     // existingItem.quantity += item.quantity * units;
     existingItem.quantity += item.quantity;
  } else {
    // item.quantity = item.quantity * units;
    item.quantity = item.quantity;
      uniqueData.push({...item});
  }
 });
  this.requirementMaterialsList = uniqueData;
}
updateIngredientInventory(){
  this.requirementAddOnIngedients.forEach(item => {
    var inStockUnits = this.calculateStocks(item);
    var committedUnits = item.ingredient_weight/(item.quantity_per_unit -
                         (item.quantity_per_unit*(item.waste/100)));
    var updatedStockUnits = inStockUnits - committedUnits;
    var inventory_pallets;
    var inventory_cases;
    var inventory_units;
    var inventory_partial;
    if(item.inventory_pallet > 0){
    inventory_pallets = Math.floor(updatedStockUnits / (Math.max(item.cases_per_pallet, 1) * Math.max(item.unit_per_case, 1)));
    inventory_cases = Math.floor((updatedStockUnits % (Math.max(item.cases_per_pallet, 1) * Math.max(item.unit_per_case, 1))) / Math.max(item.unit_per_case, 1));
    inventory_units = Math.floor((updatedStockUnits % (Math.max(item.cases_per_pallet, 1) * Math.max(item.unit_per_case, 1))) % Math.max(item.unit_per_case, 1));
    inventory_partial = (((updatedStockUnits % (Math.max(item.cases_per_pallet, 1) * Math.max(item.unit_per_case, 1))) % Math.max(item.unit_per_case, 1)) % 1) * item.quantity_per_unit;
      var newIngredient1 = {
        id: item.id,
        ingredient_name: item.ingredient_name,
        inventory_pallets: inventory_pallets,
        inventory_cases: inventory_cases,
        inventory_units: inventory_units,
        inventory_partial: inventory_partial,
        resource_type: "I"
      };
      this.resourcesIds.push(newIngredient1);
    }else if(item.inventory_cases > 0){
      inventory_cases = Math.floor(updatedStockUnits / Math.max(item.unit_per_case, 1));
      inventory_units = Math.floor(updatedStockUnits % Math.max(item.unit_per_case, 1));
      inventory_partial = ((updatedStockUnits % Math.max(item.unit_per_case, 1)) % 1) * item.quantity_per_unit;
        var newIngredient2 = {
          id: item.id,
          ingredient_name: item.ingredient_name,
          inventory_pallets: null,
          inventory_cases: inventory_cases,
          inventory_units: inventory_units,
          inventory_partial: inventory_partial,
          resource_type: "I"
        };
        this.resourcesIds.push(newIngredient2);
    }else{
      inventory_units = Math.floor((item.inventory_units + (item.inventory_partial/item.quantity_per_unit)) - committedUnits);
      inventory_partial = (((item.inventory_units + (item.inventory_partial/item.quantity_per_unit)) - committedUnits) % 1) * item.quantity_per_unit;
      var newIngredient3 = {
        id: item.id,
        ingredient_name: item.ingredient_name,
        inventory_pallets: null,
        inventory_cases: null,
        inventory_units: inventory_units,
        inventory_partial: inventory_partial,
        resource_type: "I"
      };
      this.resourcesIds.push(newIngredient3);
    }
  });
  
}
updateMaterialInventory(){
  this.requirementMaterialsList.forEach(item => {
    var inStockUnits = this.calculateStocks(item);
    var committedUnits = item.quantity/(item.quantity_per_unit -
      (item.quantity_per_unit*(item.waste/100)));
    var updatedStockUnits = inStockUnits - committedUnits;
    var inventory_pallets;
    var inventory_cases;
    var inventory_units;
    var inventory_partial;
    if(item.inventory_pallet > 0){
    inventory_pallets = Math.floor(updatedStockUnits / (Math.max(item.cases_per_pallet, 1) * Math.max(item.unit_per_case, 1)));
    inventory_cases = Math.floor((updatedStockUnits % (Math.max(item.cases_per_pallet, 1) * Math.max(item.unit_per_case, 1))) / Math.max(item.unit_per_case, 1));
    inventory_units = Math.floor((updatedStockUnits % (Math.max(item.cases_per_pallet, 1) * Math.max(item.unit_per_case, 1))) % Math.max(item.unit_per_case, 1));
    inventory_partial = (((updatedStockUnits % (Math.max(item.cases_per_pallet, 1) * Math.max(item.unit_per_case, 1))) % Math.max(item.unit_per_case, 1)) % 1) * item.quantity_per_unit;
      var material1 = {
        id: item.id,
        material_name: item.material_name,
        inventory_pallets: inventory_pallets,
        inventory_cases: inventory_cases,
        inventory_units: inventory_units,
        inventory_partial: inventory_partial,
        resource_type: "M"
      };
      this.resourcesIds.push(material1);
    }else if(item.inventory_cases > 0){
      inventory_cases = Math.floor(updatedStockUnits / Math.max(item.unit_per_case, 1));
      inventory_units = Math.floor(updatedStockUnits % Math.max(item.unit_per_case, 1));
      inventory_partial = ((updatedStockUnits % Math.max(item.unit_per_case, 1)) % 1) * item.quantity_per_unit;
        var material2 = {
          id: item.id,
          material_name: item.material_name,
          inventory_pallets: null,
          inventory_cases: inventory_cases,
          inventory_units: inventory_units,
          inventory_partial: inventory_partial,
          resource_type: "M"
        };
        this.resourcesIds.push(material2);
    }else{
      inventory_units = Math.floor((item.inventory_units + item.inventory_partial/item.quantity_per_unit) - committedUnits);
      inventory_partial = (((item.inventory_units + (item.inventory_partial/item.quantity_per_unit)) - committedUnits) % 1) * item.quantity_per_unit;
      var material3 = {
        id: item.id,
        material_name: item.material_name,
        inventory_pallets: null,
        inventory_cases: null,
        inventory_units: inventory_units,
        inventory_partial: inventory_partial,
        resource_type: "M"
      };
      this.resourcesIds.push(material3);
    }
  });
}
calculateStocks(item: any): number {
  this.InventoryUnitsValue = item.inventory_units;
  const calculatedValue =
    (item.inventory_partial / item.quantity_per_unit) +
    item.inventory_units +
    (item.unit_per_case * item.inventory_cases) +
    (item.inventory_pallet * item.unit_per_case * item.cases_per_pallet);
  return calculatedValue;
}
checkInStock(){
  var flag;
  if(this.requirementAddOnIngedients.length > 0){
  this.requirementAddOnIngedients.forEach(item => {
    if((this.calculateStocks(item) - (item.ingredient_weight/(item.quantity_per_unit -
      (item.quantity_per_unit*(item.waste/100))))) <= 0){
        flag = 0; // Need stock for ingredients
    }
  })
  this.requirementMaterialsList.forEach(item => {
    if((this.calculateStocks(item) - (item.quantity/(item.quantity_per_unit -
      (item.quantity_per_unit*(item.waste/100))))) <= 0){
        flag = 2; // Need stock for materials
    }
  })
  if(flag == 0 || flag == 2){
    return false;
  }
  return true;
}
}
  openMOStatusModal(status: number) {
    this.isMOStatusModalOpen = true;
    if(!(this.dataInStock == 1) && this.productionStatus == 0){
      this.isBlockStatus = true;
      this.modalHeading =
        STATUS_BLOCK_HEADING;
      this.modalMessage = STATUS_BLOCK_MSG;
    }
    else if (status == 1) {
      this.isBlockStatus = false;
      this.modalHeading =
        IN_PROGRESS_HEADING;
      this.modalMessage = IN_PROGRESS_MSG.replace('<total_resources>',`${this.totalResourcesCount}`);
    } else if(status == 2) {
      this.isBlockStatus = false;
      this.modalHeading = READY_TO_SHIP_HEADING;
      this.modalMessage =
        READY_TO_SHIP_MSG;
    }
    this.orderStatus = status;
  }
  openBlockModal(){
    const dialogRef = this.dialogRef.open(NewOrderBlockModalComponent, {
      data:{
        stripe_product_id: this.subscriptionInfo.stripe_product_id
      }
    })
    dialogRef.afterClosed().subscribe((res=>{
      if(res == 'ok'){
        this.CreateCustomerPortalSession();
      }
    }))
    setTimeout(()=>{
      window.scrollTo(0,0);
    },235)
  }
  CreateCustomerPortalSession() {
    this.spinner.show();
    var model = {
      CustomerId: this.user.stripe_customer_id,
      ReturnUrl: RETURN_URL,
      UserId: this.user.user_id
    }
    this.settingsService
      .GetCustomerPortalSession(model)
      .subscribe((url) => {
        if (url) {
          window.location.href = url;
          return;
        }
        this.spinner.hide();
      },
      (err) => {
        this.errorMessage("some error occured while redirecting to manage subscription portal")
        this.spinner.hide();
      });
  }
  closeMOStatusModal() {
    this.isMOStatusModalOpen = false;
  }
  successMessage(message) {
    this.showToasterOnClick();
    const data = { message: message, type: SUCCESS };
    this.toasterService.setData(data);
  }

  errorMessage(message) {
    this.showToasterOnClick();
    const data = { message: message, type: ERROR };
    this.toasterService.setData(data);
  }
  showToasterOnClick() {
    this.showToaster = true;
    setTimeout(() => {
      this.showToaster = false;
    }, 2000);
  }

  handleToasterClosed() {
    this.showToaster = false;
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
   
    if (event.key === "Escape") {
      event.preventDefault();
      // event.stopPropagation();
      event.preventDefault();
        console.log("evemt",event);
    }
}
}

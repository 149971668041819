import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AuthService, DataService, userSubject } from "./core";
import { Role } from "./core/enum/role";
import { UtilService } from "./core/services/util.service";
import * as menuData from "./menu.json";
import { UpgradeLogoutComponent } from "./upgrade-logout/upgrade-logout.component";
import { Subscription } from "rxjs";
import { UpgradeComponent } from "./account/upgrade/upgrade.component";
import { GettingStartedGuideModalComponent } from "./getting-started-guide-modal/getting-started-guide-modal.component";
import { NgxSpinnerService } from "ngx-spinner";
import { GettingStartedGuidePopupComponent } from "./getting-started-guide-modal/getting-started-guide-popup/getting-started-guide-popup/getting-started-guide-popup.component";
import { ToasterService } from "./custom-toaster/toaster.service";
import { FeedbackComponent } from "./account/feedback/feedback.component";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "prophit";
  user: any = [];
  isDashboard = false;
  toggleClass = false;
  toggleProfile = false;
  menuData: any[] = (menuData as any).default;
  subMenu = [];
  menuRouting: any;
  displayModal: boolean = false;
  displaySubscriptionModal: boolean = false;
  createdDate: Date;
  currentDate: Date;
  freeTrialdaysLeft: number;
  signupDate: Date;
  differenceInMilliseconds: number;
  event$;
  differenceInDays: number;
  isDisabled: boolean = true;
  isInputClicked: boolean = false;
  minExpiryYear = Number(new Date().getFullYear().toString().substr(-2)) + 1;
  submitted: boolean;
  loading: boolean;
  isLoaded: boolean;
  stripeCustomerId!: string;
  getFreeTrialInfo: any;
  zipCode!: string;
  isFormLoaded: boolean = false;
  isFreeTrialValid: boolean = false;
  isPageInitialize: boolean = false;
  thisIsAdmin: boolean = false;
  isLoadFirst: boolean = true;
  freeTrialExist: boolean = false;
  showUpgradeHeading: boolean = false;
  showUpgradeHeading1: boolean = false;
  NewDate: string;
  isStepsCompleted: boolean = true;
  startGuideStepList: any[] = [];
  userId: number;
  isStartGuide: boolean = false;
  lowerNotCompletedStep: any;
  totalSteps: number = 0;
  completedSteps: number = 0;
  progressPercentage: number = 0;
  showMenu:any;
  isSmallExpanded:any;
  isCollapsedManual:any;
  public isSvgClicked: boolean = false;
  constructor(
    public authService: AuthService,
    public utilService: UtilService,
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogRef: MatDialog,
    private spinner: NgxSpinnerService
  ) {
    this.authService.user.subscribe((x) => {
      this.user = x;
    });
    this.event$ = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        let currentUrl = event.url;
        var ua = navigator.userAgent;
        if (
          !currentUrl.includes("/account/register") &&
          !currentUrl.includes("/mobile-landing-page") &&
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
            ua
          )
        ) {
          window.location.href = "/mobile-landing-page";
          return;
        }

        if (currentUrl.includes("assembly")) {
          this.subMenu = this.menuData.filter((item) =>
            item.menuName.includes("Assembly")
          )[0].subMenu;
          this.showDialog();
        }
        if (currentUrl.includes("inventory")) {
          this.subMenu = this.menuData.filter((item) =>
            item.menuName.includes("Inventory")
          )[0].subMenu;
          this.showDialog();
        }
      }
      if (event instanceof NavigationEnd) {
        this.showDialog();
      }
    });
  }

  ngOnInit() {
        this.router.events.forEach((event) => {
    if(event instanceof NavigationStart) {
        this.showMenu = event.url.includes("lots") ;
        this.showMenu=event.url.includes("wixlogin") ;
        if(event.url.includes("wixlogin"))
        {
              this.router.navigate[("wixlogin")]
        }
        // this.isSmallExpanded = event.url.includes("lots");
    }
   this.isSmallExpanded = window.innerWidth <= 1260;
   if(this.isCollapsedManual){
    this.isSmallExpanded = this.isCollapsedManual;
   }
  });
    let user = JSON.parse(localStorage.getItem("user"));
    this.isPageInitialize = true;
    // let freeTrialExistNo = user[0]?.is_free_trial;
    // let SubscriptionDaysLeft = user[0]?.DaysLeft;
    this.thisIsAdmin = this.isAdmin("admin");
    // if (!freeTrialExistNo) {
    //   this.showUpgradeDialog();
    // }
    // console.warn(user);
    // if (SubscriptionDaysLeft <= 0 && !freeTrialExistNo) {
    //   this.showUpgradeHeading1 = true;
    // }
    if (user != null) {
      this.isLoadFirst = false;
      this.userId = user[0].id;
      this.subscribeUserSubject();
    }
    if(this.userId){
      this.getStartGuideStepList();
    }
    this.dataService.getStartGuideSource().subscribe((res) => {
      if (res) {
        this.isStartGuide = true;
        if(this.userId){
          this.getStartGuideStepList();
        }
      }
      else{
        this.isStartGuide = false
      }
    });
  }
  logout() {
    for (let i = 0; i < this.menuData.length; i++){
      this.menuData[i].isActive = false;
      this.menuData[i].showSubMenu = false;
      for (const smenu of this.menuData[i].subMenu) {
        smenu.isActive = false;
      }
    }
    for (const item of this.menuData) {
      for (const smenu of item.subMenu) {
        smenu.isActive = false;
      }
    }
    sessionStorage.removeItem(`review_details_${this.user[0].id}`);
    this.dialogRef.closeAll();
    this.authService.logout();
    localStorage.clear();
  }

  yourprofile() {
    if (this.isAdmin("admin")) {
      this.router.navigate(["/admin/settings"], { relativeTo: this.route });
    } else {
      this.router.navigate(["/settings/account"], { relativeTo: this.route });
    }
  }

  settings() {
    this.router.navigate(["setting"], { relativeTo: this.route });
  }

  toggleMenu(index) {
    this.toggleClass = !this.toggleClass;
    const subMenuId = document.getElementById("subMenu" + index);
    if (this.toggleClass) {
      subMenuId.classList.remove("hidden");
    } else {
      subMenuId.classList.add("hidden");
    }
  }

  onSubMenu(index: number): void {
    for (let i = 0; i < this.menuData.length; i++) {
      if (i === index) {
        this.menuData[i].showSubMenu = !this.menuData[i].showSubMenu;
        this.menuData[i].isActive = true;
      } else {
        this.menuData[i].showSubMenu = false;
        this.menuData[i].isActive = false;
        for (const smenu of this.menuData[i].subMenu) {
          smenu.isActive = false;
        }
      }
    }
  }

  getSubMenuItem(index:number,itemName:string){
    var subMenu = this.menuData[index].subMenu;
    var subMenuItem = subMenu.find(x=> x.menuName == itemName)
    return subMenuItem;
  }

  onSubMenuItemClick(subMenuItem: any) {
    for (const item of this.menuData) {
      for (const smenu of item.subMenu) {
        smenu.isActive = false;
      }
    }
    subMenuItem.isActive = true;
  }

  onMainMenu() {
    this.subMenu = [];
    this.router.navigate([this.router.url], { relativeTo: this.route });
  }

  isAdmin(role) {
    if (this.user != null) {
      return role.includes(this.user[0].role) ? true : false;
    }
  }

  showDialog() {
    this.displayModal = true;
  }

  showUpgradeDialog() {
    this.isInputClicked = true;
    if (!this.isFreeTrialValid) {
      this.dialogRef.open(UpgradeComponent);
    }
    this.submitted = false;
  }

  onCancel() {
    if (this.isDashboard) {
      this.dialogRef.open(UpgradeLogoutComponent);
    }
  }

  subscribeUserSubject() {
    userSubject.subscribe((data) => {
      let user = data;
      if (user != null && user.length > 0) {
        this.thisIsAdmin = user[0].role === "admin" ? true : false;
        this.isPageInitialize = true;
        this.createdDate = user[0].created_date;
        this.stripeCustomerId = user[0].stripe_customer_id;
        // this.getFreeTrialInfo = this.authService
        //   .getFreeTrialInfo(this.stripeCustomerId)
        //   .subscribe((res) => {
        //     let localstorageData = JSON.parse(localStorage.getItem("user"));
        //     let freeTrialFromlocalstorageData =
        //       localstorageData[0].is_free_trial;
        //     if (freeTrialFromlocalstorageData == true) {
        //       this.freeTrialExist = true;
        //     } else {
        //       this.freeTrialExist = false;
        //     }
        //   });
        this.signupDate = new Date(this.createdDate);
        this.currentDate = new Date();
        this.differenceInMilliseconds =
          this.currentDate.getTime() - this.signupDate.getTime();
        this.differenceInDays = Math.floor(
          this.differenceInMilliseconds / (1000 * 60 * 60 * 24)
        );
        // this.freeTrialdaysLeft = 14 - this.differenceInDays;
        // if (this.freeTrialdaysLeft <= 0) {
        //   this.isFreeTrialValid = false;
        //   this.showUpgradeHeading = true;
        // }
        if (this.isLoadFirst) {
          this.ngOnInit();
        }
      }
    });
  }

  getStartGuideStepList() {
    this.dataService.getStartGuideStepList(this.userId).subscribe((res) => {
      if (res != null && res.ResponseData.length > 0) {
        this.startGuideStepList = res.ResponseData;
        this.calculateStartGuideSteps();
        this.progressPercentage = (100 / this.totalSteps) * this.completedSteps;
        if (this.isStartGuide) {
          if(!this.isStepsCompleted){
          this.firstNotCompletedStep();
          this.dialogRef.open(GettingStartedGuidePopupComponent, {
            data: {
              dataSource: this.lowerNotCompletedStep,
              totalSteps: this.totalSteps,
              completedSteps: this.completedSteps,
              progressPercentage: this.progressPercentage,
            },
          });
          setTimeout(()=>{
            window.scrollTo(0,0);
          },50)
          this.dataService.setStartGuideSource(false);
        }
        }
      }
    });
  }


  openFeedback()
  {
    this.dialogRef.open(FeedbackComponent, {
      disableClose: true
    });
  }

  calculateStartGuideSteps() {
    this.totalSteps = 0;
    this.completedSteps = 0;
    this.startGuideStepList.forEach((x: any) => {
      this.totalSteps += x.TotalSteps;
      this.completedSteps += x.CompletedSteps;
    });
    if (this.totalSteps == this.completedSteps) {
      this.isStepsCompleted = true;
    } else {
      this.isStepsCompleted = false;
    }
  }

  openStartGuideDialog() {
    this.spinner.show();
    this.dialogRef.open(GettingStartedGuideModalComponent);
  }

  firstNotCompletedStep() {
    outerLoop: for (let i = 0; i < this.startGuideStepList.length; i++) {
      for (let j = 0; j < this.startGuideStepList[i].childSteps.length; j++) {
        if (this.startGuideStepList[i].childSteps[j].IsCompleted == false) {
          this.lowerNotCompletedStep = this.startGuideStepList[i].childSteps[j];
          break outerLoop;
        }
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterService } from 'src/app/custom-toaster/toaster.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-wix-login',
  templateUrl: './wix-login.component.html',
  styleUrls: ['./wix-login.component.css']
})
export class WixLoginComponent implements OnInit {
  email: any;
  showToaster = false;
  subscription: Subscription;
  model:any;
  modelEmail:any;
  modelpassword:any;
  constructor(private activatedRoute:ActivatedRoute,private authService: AuthService,
    private spinner: NgxSpinnerService,
    private toasterService: ToasterService,
    private router: Router,) {
     }

  ngOnInit(): void {
   const modelString = this.activatedRoute.snapshot.queryParamMap.get("model");
   this.activatedRoute.queryParams.subscribe(params => {
    this.email = params['email'];
  });
   if (modelString) {
   try {
    this.model = JSON.parse(decodeURIComponent(modelString));
   this.modelEmail=this.model.email,
   this.modelpassword=this.model.password
   } catch (error) {
    console.error("Failed to decode and parse model:", error);
  }
  this.loginUsingEmailAndPassword();
 }
 

if(this.email)
{
  this.spinner.show();
  this.authService.googlelogin(this.email).subscribe(item => {
      
      this.spinner.hide();
      if (item && item.length > 0 && item[0].id > 0) {
          if (item[0].role == "admin") {
              this.router.navigate(['/admin/subscribers']);
          }
          else{
            this.successMessage("Logged in successfully.");
            this.router.navigate(['/dashboard']);
          }
      } else {
          this.errorMessage("Invalid credentials.")
          location.href="https://www.prophitapp.com/sign-in"
      }
  })
this.spinner.hide();
}
   
  };

  successMessage(message) {
    this.showToasterOnClick();
    const data = { message: message, type: 'success' };
    this.toasterService.setData(data);
  }

  errorMessage(message) {
    this.showToasterOnClick();
    const data = { message: message, type: 'error' };
    this.toasterService.setData(data);
  }

  toasterSubscription() {
    this.subscription = this.toasterService.data$.subscribe((data) => {
      if (data) {
        this.showToasterOnClick();
      }
    });
  }
  showToasterOnClick() {
    this.showToaster = true;
    setTimeout(() => {
      this.showToaster = false;
    }, 2000);
  }


  loginUsingEmailAndPassword()
{
  this.spinner.show();
  this.authService.login(this.modelEmail, this.modelpassword)
  .subscribe(item => {
      
      this.spinner.hide();
      if (item && item.length > 0 && item[0].id > 0) {
          if (item[0].role == "admin") {
              this.router.navigate(['/admin/subscribers']);
          }
          else{
            this.successMessage("Logged in successfully.");
            this.router.navigate(['/dashboard']);
          }
      } else {
          this.errorMessage("Invalid credentials.")
          location.href="https://www.prophitapp.com/sign-in"
      }
  },
      (err) => {
          this.spinner.hide();
          // this.toaster.error('Unable to login. Please try again later');
          
      }
  );
}
}




